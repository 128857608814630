import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { toggleRTL } from '../../store/slices/rtlSlice';

import styles from './MainLayoutFooter.module.css';
import footerLogo from '../../assets/images/logo.jpg';
import '../../i18n';


const MainLayoutFooter = () => {
  const { t, i18n } = useTranslation();
  const isRTL = useSelector((state)=> state.rtl.isRTL);
  const dispatch = useDispatch();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
    dispatch(toggleRTL());
  };

  return (
    <footer className={styles.footer}>
        <div className={styles.footerContent}>

          <div className={styles.footerSection}>
                <h3 className={styles.quickLinksTitle}>{t('home.footer.quickLinks')}</h3>
                <div className={styles.quickLinksWrapper}>
                    <ul className={`${styles.footerLinks} ${styles.quickLinksList}`}>
                        <li>
                            <a href="/people">{t('home.footer.people')}</a>
                            <ul className={styles.subLinksList}>
                                <li><a href="/retail-icons">{t('home.footer.retailIcons')}</a></li>
                                <li><a href="/ceos">{t('home.footer.ceos')}</a></li>
                                <li><a href="/women-leaders">{t('home.footer.womenLeaders')}</a></li>
                                <li><a href="/cios">{t('home.footer.cios')}</a></li>
                            </ul>
                        </li>
                        <li><a href="/companies">{t('home.footer.companies')}</a></li>
                        <li><a href="/brands">{t('home.footer.brands')}</a></li>
                    </ul>

                    <ul className={`${styles.footerLinks} ${styles.quickLinksList}`}>
                        <li><a href="/tech-providers">{t('home.footer.techProviders')}</a></li>
                        <li><a href="/malls">{t('home.footer.malls')}</a></li>
                        <li><a href="/consultants">{t('home.footer.consultants')}</a></li>
                        <li><a href="/other-services">{t('home.footer.othrSrvcPrds')}</a></li>
                    </ul>
                </div>
          </div>

          <div className={styles.footerSection}>
            <h3>{t('home.footer.cntcts')}</h3>
            <ul className={`${styles.footerLinks} ${styles.contactFooter}`}>
              <li><a href="#">
              <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.8359 17.5352H11.6367V11.1719H6.36328V17.5352H3.16406V8.00781H2.10938V18.5898H15.8906V8.00781H14.8359V17.5352ZM7.41797 17.5352V12.2266H10.582V17.5352H7.41797ZM17.7891 7.44531L17.1562 8.28906L9 2.3125L0.84375 8.28906L0.210938 7.44531L9 0.976562L17.7891 7.44531Z"
                    fill="#004DDA"
                  />
                </svg> 
                {t('home.footer.home')}</a></li>
              <li><a href="#"><svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5938 6.73291C15.5742 2.97119 12.5156 0.369629 9 0.369629C5.51953 0.369629 2.42578 2.97119 1.40625 6.73291C0.632812 6.83838 0 7.50635 0 8.31494V12.5337C0 13.2017 0.421875 13.7642 0.984375 14.0103C1.19531 15.8735 2.74219 17.3149 4.64062 17.3149H7.52344C7.73438 17.9126 8.29688 18.3696 9 18.3696H12.1641C13.043 18.3696 13.7812 17.6665 13.7812 16.7876C13.7812 15.9087 13.043 15.2056 12.1641 15.2056H9C8.29688 15.2056 7.73438 15.6274 7.52344 16.2603H4.64062C3.375 16.2603 2.28516 15.3462 2.03906 14.1509H3.16406V13.061H4.21875V7.7876H3.16406V6.73291H2.53125C3.51562 3.604 6.08203 1.42432 9 1.42432C11.918 1.42432 14.4844 3.56885 15.4688 6.73291H14.8359V7.7876H13.7812V13.061H14.8359V14.1509H16.418C17.2969 14.1509 18 13.4126 18 12.5337V8.31494C18 7.50635 17.3672 6.83838 16.5938 6.73291ZM9 16.2603H12.1641C12.4805 16.2603 12.6914 16.5063 12.6914 16.7876C12.6914 17.0688 12.4805 17.3149 12.1641 17.3149H9C8.71875 17.3149 8.47266 17.0688 8.47266 16.7876C8.47266 16.5063 8.71875 16.2603 9 16.2603ZM2.10938 13.061H1.58203C1.30078 13.061 1.05469 12.8501 1.05469 12.5337V8.31494C1.05469 8.03369 1.30078 7.7876 1.58203 7.7876H2.10938V13.061ZM16.9453 12.5337C16.9453 12.8501 16.6992 13.061 16.418 13.061H15.8906V7.7876H16.418C16.6992 7.7876 16.9453 8.03369 16.9453 8.31494V12.5337Z"
                    fill="#004DDA"
                  />
                </svg> {t('home.footer.support')}</a></li>
              <li><a href="#"><svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0.869141V14.6504H18V0.869141H0ZM9 9.16602L1.6875 1.92383H16.3125L9 9.16602ZM6.08203 7.75977L1.05469 12.7168V2.80273L6.08203 7.75977ZM6.85547 8.5332L9 10.6426L11.1094 8.56836L16.1719 13.5957H1.72266L6.85547 8.5332ZM11.8477 7.83008L16.9453 2.80273V12.8223L11.8477 7.83008Z"
                    fill="#004DDA"
                  />
                </svg> {t('home.footer.contact')}</a></li>
            </ul>
          </div>

          
          <div className={`${styles.footerSection} ${styles.socialLinksStratch}`}>
            <div className="text-center">
              <img src={footerLogo} alt="Retailopedia" className={styles.footerLogo} />
            </div>
            <h3>{t('home.footer.keepTouch')}</h3>
            <div className={styles.newsLetterCont}>
              <div className={styles.newsInrFrmCont}>
                <input type="email" placeholder={t('home.footer.yourEml')} className={`${styles.newsLtreml} ${isRTL ? styles.newsLtremlAr : ''}`}/>
                <button className={styles.newLtrBtn} >{t('home.footer.submit')}</button>
              </div>
            </div>

            <h3 className={`${styles.folloTxt}`}>{t('home.footer.follous')}</h3>
            <div className={styles.socialLinks}>
            <a href="#"
              ><svg
                width="10"
                height="21"
                viewBox="0 0 10 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.64844 7.34229L9.29688 10.7798H6.52344V20.7798H2.42188V10.7798H0.351562V7.34229H2.42188V5.27197C2.42188 2.45947 3.55469 0.779785 6.875 0.779785H9.64844V4.21729H7.92969C6.64062 4.21729 6.52344 4.7251 6.52344 5.62354V7.34229H9.64844Z"
                  fill="#555555"
                />
              </svg>
            </a>
            <a href="#"
              ><svg
                width="21"
                height="17"
                viewBox="0 0 21 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6797 4.71729C18.7188 4.87354 18.7188 5.06885 18.7188 5.2251C18.7188 10.6548 14.5781 16.9048 7.03906 16.9048C4.73438 16.9048 2.54688 16.2407 0.75 15.0688C1.0625 15.1079 1.41406 15.1079 1.72656 15.1079C3.64062 15.1079 5.4375 14.4829 6.84375 13.3501C5.00781 13.311 3.52344 12.1392 3.01562 10.4985C3.25 10.5376 3.52344 10.5767 3.75781 10.5767C4.14844 10.5767 4.5 10.5376 4.85156 10.4595C2.97656 10.0688 1.57031 8.42822 1.57031 6.43604C1.57031 6.39697 1.57031 6.39697 1.57031 6.35791C2.11719 6.67041 2.74219 6.86572 3.40625 6.90479C2.3125 6.1626 1.57031 4.9126 1.57031 3.46729C1.57031 2.7251 1.80469 2.02197 2.15625 1.39697C4.14844 3.89697 7.19531 5.5376 10.5938 5.69385C10.5156 5.38135 10.4766 5.06885 10.4766 4.75635C10.4766 2.49072 12.3125 0.654785 14.5781 0.654785C15.7891 0.654785 16.8438 1.1626 17.5859 1.94385C18.5234 1.7876 19.4219 1.43604 20.2031 0.967285C19.8906 1.90479 19.2266 2.7251 18.4062 3.23291C19.2266 3.11572 20.0078 2.92041 20.75 2.56885C20.2031 3.38916 19.5 4.13135 18.6797 4.71729Z"
                  fill="#555555"
                />
              </svg>
            </a>
            <a href="#"
              ><svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5 3.86572V5.38916H17.4141V8.4751H15.8906V5.38916H12.8047V3.86572H15.8906V0.779785H17.4141V3.86572H20.5ZM12.7266 15.8188C12.7266 17.186 12.0625 18.3188 11.0469 19.2173C9.64062 20.3892 7.76562 20.7798 6.00781 20.7798C3.74219 20.7798 0.5 19.8032 0.5 17.0298C0.5 16.4829 0.695312 15.897 0.929688 15.3892C2.02344 13.2407 5.34375 12.6938 7.49219 12.6157C7.10156 12.1079 6.75 11.522 6.75 10.8579C6.75 10.4282 6.86719 10.1938 6.98438 9.80322C6.71094 9.84229 6.4375 9.88135 6.16406 9.88135C3.82031 9.88135 1.82812 8.1626 1.82812 5.74072C1.82812 3.43604 3.58594 1.48291 5.77344 1.01416C6.51562 0.85791 7.25781 0.779785 8 0.779785H13.2734L11.6328 1.71729H10.0312C11.2031 2.45947 11.8281 3.94385 11.8281 5.27197C11.8281 8.4751 9.13281 8.70947 9.13281 10.272C9.13281 11.8345 12.7266 12.4595 12.7266 15.8188ZM9.60156 6.48291C9.60156 4.64697 8.58594 1.63916 6.35938 1.63916C4.79688 1.63916 4.05469 3.04541 4.05469 4.45166C4.05469 6.2876 5.22656 9.13916 7.375 9.13916C9.01562 9.13916 9.60156 7.96729 9.60156 6.48291ZM11.0469 16.8345C11.0469 15.1548 9.44531 14.2173 8.23438 13.3579C8.03906 13.3579 7.84375 13.3579 7.60938 13.3579C5.65625 13.3579 2.72656 13.9829 2.72656 16.4829C2.72656 18.7876 5.30469 19.686 7.25781 19.686C9.01562 19.686 11.0469 18.9438 11.0469 16.8345Z"
                  fill="#555555"
                />
              </svg>
            </a>
            <a href="#"
              ><svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9453 7.08432C15.9453 11.6156 13.4453 14.9359 9.77344 14.9359C8.52344 14.9359 7.35156 14.2718 6.96094 13.5296C6.29688 16.1468 6.17969 16.6546 6.17969 16.6546C5.94531 17.514 5.4375 18.4124 5.00781 19.0765C3.79688 19.9749 3.67969 18.6078 3.67969 18.6078C3.64062 17.8265 3.64062 16.8499 3.875 15.9906C3.875 15.9906 4.07031 15.0531 5.32031 9.77963C4.96875 9.03745 4.96875 7.9437 4.96875 7.9437C4.96875 6.26401 5.94531 4.97495 7.15625 4.97495C8.21094 4.97495 8.71875 5.79526 8.71875 6.73276C8.71875 7.74838 8.05469 9.31088 7.70312 10.7562C7.42969 11.9671 8.32812 12.9828 9.5 12.9828C11.6875 12.9828 13.1328 10.1703 13.1328 6.88901C13.1328 4.38901 11.4531 2.51401 8.36719 2.51401C4.89062 2.51401 2.74219 5.09213 2.74219 7.98276C2.74219 8.99838 3.05469 9.66245 3.52344 10.2484C3.71875 10.4828 3.75781 10.5609 3.67969 10.8734C3.60156 11.0687 3.48438 11.5765 3.44531 11.7718C3.36719 12.0843 3.13281 12.1624 2.85938 12.0843C1.29688 11.4203 0.515625 9.66245 0.515625 7.70932C0.515625 4.46713 3.25 0.560883 8.67969 0.560883C13.0547 0.560883 15.9453 3.72495 15.9453 7.08432Z"
                  fill="#555555"
                />
              </svg>
            </a>
            <a href="#"
              ><svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3125 0.779785H4.6875C2.10938 0.779785 0 2.88916 0 5.46729V16.0923C0 18.6704 2.10938 20.7798 4.6875 20.7798H15.3125C17.8906 20.7798 20 18.6704 20 16.0923V5.46729C20 2.88916 17.8906 0.779785 15.3125 0.779785ZM18.8281 16.0923C18.8281 18.0063 17.2266 19.6079 15.3125 19.6079H4.6875C2.77344 19.6079 1.17188 18.0063 1.17188 16.0923V9.02197H4.96094C4.60938 9.7251 4.41406 10.5063 4.41406 11.3657C4.41406 14.4517 6.91406 16.9517 10 16.9517C13.0859 16.9517 15.5859 14.4517 15.5859 11.3657C15.5859 10.5063 15.3906 9.7251 15.0391 9.02197H18.8281V16.0923ZM14.4141 11.3657C14.4141 13.7876 12.4219 15.7798 10 15.7798C7.57812 15.7798 5.58594 13.7876 5.58594 11.3657C5.58594 8.94385 7.57812 6.95166 10 6.95166C12.4219 6.95166 14.4141 8.94385 14.4141 11.3657ZM14.2969 7.8501C13.2812 6.6001 11.7188 5.77979 10 5.77979C8.28125 5.77979 6.71875 6.6001 5.70312 7.8501H1.17188V5.46729C1.17188 3.55322 2.77344 1.95166 4.6875 1.95166H15.3125C17.2266 1.95166 18.8281 3.55322 18.8281 5.46729V7.8501H14.2969ZM16.9922 3.98291V5.50635C16.9922 5.85791 16.7188 6.13135 16.3672 6.13135H14.7656C14.4141 6.13135 14.1016 5.85791 14.1016 5.50635V3.98291C14.1016 3.63135 14.4141 3.35791 14.7656 3.35791H16.3672C16.7188 3.35791 16.9922 3.63135 16.9922 3.98291Z"
                  fill="#555555"
                />
              </svg>
            </a>
            </div>
          </div>
        </div>
        
        <div className={styles.footerBottom}>
            <div className={styles.customSelectWrapper}>
                <svg className={styles.customSelectIcon} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1767 10.3832C12.3473 10.2126 12.6246 10.2134 12.7952 10.384C12.9658 10.5546 12.9658 10.8319 12.7952 11.0025C11.4329 12.3651 9.64356 13.0456 7.855 13.0456C7.82 13.0456 7.785 13.0413 7.75 13.0404V14.0415H9.0625C9.30395 14.0415 9.5 14.2376 9.5 14.479C9.5 14.7204 9.30395 14.9165 9.0625 14.9165H5.5625C5.32106 14.9165 5.125 14.7204 5.125 14.479C5.125 14.2376 5.32106 14.0415 5.5625 14.0415H6.875V12.9688C5.42524 12.7648 4.02688 12.1149 2.91481 11.0017C1.59793 9.68482 0.871681 7.92963 0.871681 6.0615C0.871681 4.19338 1.59711 2.439 2.91481 1.12213C3.08543 0.951504 3.3627 0.951504 3.53332 1.12213C3.70395 1.29275 3.70395 1.57002 3.53332 1.74064C2.38188 2.89236 1.74668 4.4269 1.74668 6.0615C1.74668 7.69611 2.38106 9.23064 3.53414 10.3832C5.91688 12.7667 9.79395 12.7648 12.1767 10.3832ZM7.855 0.916504C10.696 0.916504 13 3.21939 13 6.0615C13 8.90361 10.696 11.2065 7.855 11.2065C5.01399 11.2065 2.71 8.90252 2.71 6.0615C2.71 3.22049 5.01289 0.916504 7.855 0.916504ZM7.855 10.3315C10.2096 10.3315 12.125 8.41607 12.125 6.0615C12.125 3.70693 10.2096 1.7915 7.855 1.7915C5.50043 1.7915 3.585 3.70693 3.585 6.0615C3.585 8.41607 5.50043 10.3315 7.855 10.3315Z"
                    fill="#555555"/>
                </svg>
                <select name="menu" onChange={(e) => handleLanguageChange(e.target.value)}>
                    <option value="en">English</option>
                    <option value="ar">العربية</option>
                </select>
            </div>

          <div className={styles.footerLinksBottom}>
            <a href="#">{t('home.footer.toc')}</a>
            <a href="#">{t('home.footer.privacy')}</a>
          </div>
        </div>
    </footer>
  );
};

export default MainLayoutFooter;