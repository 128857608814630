import React from "react";
import styles from "./styles.module.css";
import headerLogo from "../../../assets/images/logo.jpg";
import mobileIcon from "../../../assets/images/mobile.png";

const Header = () => {
  return (
    <>
      <header className={styles.headerFixed}>
        <div className={styles.headerLimiter}>
          <img src={headerLogo} className={styles.headerLogo} />

          <nav>
            <a href="#">People</a>
            <a href="#">Companies</a>
            <a href="#">Retailers</a>
            <a href="#">Retail Enablers</a>
            <a href="#">Shopping Centers</a>
            <a href="#">Consultants</a>
            <a href="#">
              <p>Get the app</p>
            </a>
            <a href="#">
              {" "}
              <button className={`${styles.headerButton} ${styles.joinButton}`}>
                Join now
              </button>
            </a>
            <a href="#">
              <button className={`${styles.signInButton}`}>Sign in</button>
            </a>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
